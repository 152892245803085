export const casinoTabs = {
  roulettetab: {
    SpeedRoulette: {
      href: "/casino/ezugi/SpeedRoulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/speed-roulette1.png", alt: "" },
    },
    DiamondRoulette: {
      href: "/casino/ezugi/DiamondRoulette",
      code: "221003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/diamond-roulette1.png", alt: "" },
    },
    NamasteRoulette: {
      href: "/casino/ezugi/NamasteRoulette",
      code: "221005",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/mamaste-roulette1.png", alt: "" },
    },
    TurkishRoulette: {
      href: "/casino/ezugi/TurkishRoulette",
      code: "5501000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/turkish-roulette.png", alt: "" },
    },
    AutoRoulette: {
      href: "/casino/ezugi/AutoRoulette",
      code: "5001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
    },
    SpeedAutoRoulette: {
      href: "/casino/ezugi/SpeedAutoRoulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/speed-auto-roulette.png", alt: "" },
    },
    OracleRoulette2: {
      href: "/casino/ezugi/OracleRoulette2",
      code: "611001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/oracle-roulette-2.png", alt: "" },
    },
    PortomasoRoulette1: {
      href: "/casino/ezugi/PortomasoRoulette1",
      code: "611004",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/portomaso-roulette-1.jpg", alt: "" },
    },
    ItalianRoulette: {
      href: "/casino/ezugi/ItalianRoulette",
      code: "1000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/italian-roulette.jpg", alt: "" },
    },
    SpanishRoulette: {
      href: "/casino/ezugi/SpanishRoulette",
      code: "411000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/spanish-roulette1.jpg", alt: "" },
    },
    RussianRoulette: {
      href: "/casino/ezugi/RussianRoulette",
      code: "601000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/russian-roulette.jpg", alt: "" },
    },
    FiestaRoulette: {
      href: "/casino/ezugi/FiestaRoulette",
      code: "431001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/fiesta-roulette1.jpg", alt: "" },
    },
    PrestigeAutoRoulette: {
      href: "/casino/ezugi/PrestigeAutoRoulette",
      code: "221004",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/prestige-auto-oulette.jpg", alt: "" },
    },
    HandCasinoHoldem: {
      href: "/casino/evolution/HandCasinoHoldem",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/11.png", alt: "" },
    },
  },
  baccarattab: {
    Baccaratpro: {
      href: "/casino/ezugi/Baccaratpro",
      code: "100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat01.png", alt: "" },
    },
    Nocommissiobaccarat: {
      href: "/casino/ezugi/Nocommissiobaccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat02.png", alt: "" },
    },
    Knockoutbaccarat: {
      href: "/casino/ezugi/Knockoutbaccarat",
      code: "120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat03.png", alt: "" },
    },
    Super6baccarat: {
      href: "/casino/ezugi/Super6baccarat",
      code: "32100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat04.png", alt: "" },
    },
    Marinabaccarat: {
      href: "/casino/ezugi/Marinabaccarat",
      code: "130",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat05.png", alt: "" },
    },

    Marina02baccarat: {
      href: "/casino/ezugi/Marina02baccarat",
      code: "32101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat06.png", alt: "" },
    },
    Salsabaccarat: {
      href: "/casino/ezugi/Salsabaccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat07.png", alt: "" },
    },
    Baccarat: {
      href: "/casino/ezugi/Baccarat",
      code: "106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat08.png", alt: "" },
    },
    Baccaratpro02: {
      href: "/casino/ezugi/Baccaratpro02",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat09.png", alt: "" },
    },
    SpeedBaccarat: {
      href: "/casino/ezugi/SpeedBaccarat",
      code: "105",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat010.png", alt: "" },
    },
    Marina03baccarat: {
      href: "/casino/ezugi/Marina03baccarat",
      code: "32103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat010.png", alt: "" },
    },
  },
  AndarBahartab: {
    AndarBaharLive: {
      href: "/casino/ezugi/AndarBaharLive",
      code: "228000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/AndarBahar01.png", alt: "" },
    },
    AndarBaharMarnia: {
      href: "/casino/ezugi/AndarBaharMarnia",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/AndarBahar02.png", alt: "" },
    },
  },
  TeenPattitab: {
    TeenPatti01: {
      href: "/casino/ezugi/TeenPatti01",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker01.png", alt: "" },
    },
    TeenPatti02: {
      href: "/casino/ezugi/TeenPatti02",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker02.png", alt: "" },
    },
    TeenPatti03: {
      href: "/casino/ezugi/TeenPatti03",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker03.png", alt: "" },
    },
    TeenPatti04: {
      href: "/casino/ezugi/TeenPatti04",
      code: "507000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker04.png", alt: "" },
    },
    TeenPatti05: {
      href: "/casino/ezugi/TeenPatti05",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker05.png", alt: "" },
    },
    TeenPatti06: {
      href: "/casino/ezugi/TeenPatti06",
      code: "507000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker06.jpeg", alt: "" },
    },
    TeenPatti07: {
      href: "/casino/ezugi/TeenPatti07",
      code: "150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker07.png", alt: "" },
    },
    TeenPatti08: {
      href: "/casino/ezugi/TeenPatti08",
      code: "150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker08.png", alt: "" },
    },
  },
  Blackjacktab: {
    Blackjack: {
      href: "/casino/ezugi/Blackjack",
      code: "201",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack01.png", alt: "" },
    },
    GoldBlackjack: {
      href: "/casino/ezugi/GoldBlackjack",
      code: "1",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack02.png", alt: "" },
    },
    BlackjackPro: {
      href: "/casino/ezugi/BlackjackPro",
      code: "224",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack03.png", alt: "" },
    },
    VIPBlackjack: {
      href: "/casino/ezugi/VIPBlackjack",
      code: "225",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack04.png", alt: "" },
    },
    BlackjackAutoSplit: {
      href: "/casino/ezugi/BlackjackAutoSplit",
      code: "226",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack05.png", alt: "" },
    },
    TurkceBlackjack: {
      href: "/casino/ezugi/TurkceBlackjack",
      code: "501",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack06.png", alt: "" },
    },
    LiveBlackjack: {
      href: "/casino/ezugi/LiveBlackjack",
      code: "221",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack07.png", alt: "" },
    },
    RumbaBlackjack: {
      href: "/casino/ezugi/RumbaBlackjack",
      code: "411",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack09.png", alt: "" },
    },
    DiamondBlackjack: {
      href: "/casino/ezugi/DiamondBlackjack",
      code: "225",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack010.png", alt: "" },
    },
    Rumba02Blackjack: {
      href: "/casino/ezugi/Rumba02Blackjack",
      code: "414",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack011.png", alt: "" },
    },
    VipBlackjack: {
      href: "/casino/ezugi/VipBlackjack",
      code: "224",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack011.png", alt: "" },
    },
    Vip02Blackjack: {
      href: "/casino/ezugi/Vip02Blackjack",
      code: "226",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack013.png", alt: "" },
    },
    TurkishBlackjack: {
      href: "/casino/ezugi/TurkishBlackjack",
      code: "501",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack015.png", alt: "" },
    },
    BlackjackLive: {
      href: "/casino/ezugi/BlackjackLive",
      code: "422",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack016.png", alt: "" },
    },
    BlackjackTurkce: {
      href: "/casino/ezugi/BlackjackTurkce",
      code: "5",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack016.png", alt: "" },
    },
    PlatinumBlackjack: {
      href: "/casino/ezugi/PlatinumBlackjack",
      code: "5051",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack019.png", alt: "" },
    },
  },
  thirtytwoCardtab: {
    thirtytwoCards: {
      href: "/casino/ezugi/thirtytwoCards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/card/32cards01.png", alt: "" },
    },
  },
  sevenLuckytab: {
    sevenLucky: {
      href: "/casino/ezugi/sevenLucky",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/lucky7/Lucky011.png", alt: "" },
    },
  },
  sicbotab: {
    sicbodice01: {
      href: "/casino/ezugi/sicbodice01",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice01.png", alt: "" },
    },
    sicbodice02: {
      href: "/casino/ezugi/sicbodice02",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice02.png", alt: "" },
    },
    sicbodice03: {
      href: "/casino/ezugi/sicbodice03",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice03.png", alt: "" },
    },
    sicbodice04: {
      href: "/casino/ezugi/sicbodice04",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice04.jpeg", alt: "" },
    },
    sicbodice05: {
      href: "/casino/ezugi/sicbodice05",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice05.png", alt: "" },
    },
  },
  spribe: {
    href: "/casino/sprie",
    code: "",
    provider: "spribe",
    homeUrl: "",
    games: {
      mines: {
        href: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: "",
        cover: { src: "./images/spribe/02.jpg", alt: "" },
      },
      goal: {
        href: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: "",
        cover: { src: "./images/spribe/06.png", alt: "" },
      },
      dice: {
        href: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: "",
        cover: { src: "./images/spribe/07.jpg", alt: "" },
      },
      aviator: {
        href: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: "",
        cover: { src: "./images/spribe/03.png", alt: "" },
      },
      plinko: {
        href: "/casino/spribe/plinko",
        code: "plinko",
        casino: "spribe",
        provider: "plinko",
        homeUrl: "",
        cover: { src: "./images/spribe/08.jpg", alt: "" },
      },
      miniroulette: {
        href: "/casino/spribe/miniroulette",
        code: "mini-roulette",
        casino: "spribe",
        provider: "mini-roulette",
        homeUrl: "",
        cover: { src: "./images/spribe/04.png", alt: "" },
      },
      hilo: {
        href: "/casino/spribe/hilo",
        code: "hi-lo",
        casino: "spribe",
        provider: "hi-lo",
        homeUrl: "",
        cover: { src: "./images/spribe/05.png", alt: "" },
      },
    },
  },
  Slots: {
    twinspin: {
      href: "/casino/ezugi/twinspin",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/twin-and-spin.jpg", alt: "" },
    },
    gonzoquest: {
      href: "/casino/ezugi/gonzoquest",
      code: "1100018",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/gonzo-treasure-hunt.jpg", alt: "" },
    },

    asgardianstones: {
      href: "/casino/ezugi/asgardianstones",
      code: "1100096",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/asgardianstones1.jpg", alt: "" },
    },
    secrets: {
      href: "/casino/ezugi/secrets",
      code: "1100106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/secrets.jpg", alt: "" },
    },
    grandwheel: {
      href: "/casino/ezugi/grandwheel",
      code: "1100150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/Grand-wheel.jpg", alt: "" },
    },
    Strike777: {
      href: "/casino/ezugi/Strike777",
      code: "1100031",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Nights1000: {
      href: "/casino/ezugi/Nights1000",
      code: "1100003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashUltimate: {
      href: "/casino/ezugi/CashUltimate",
      code: "1100070",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashVolt: {
      href: "/casino/ezugi/CashVolt",
      code: "1100071",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DazzleMegaways: {
      href: "/casino/ezugi/DazzleMegaways",
      code: "1100083",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DivinieFortuneMegaways: {
      href: "/casino/ezugi/DivinieFortuneMegaways",
      code: "1100089",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Dynamiteriches: {
      href: "/casino/ezugi/Dynamiteriches",
      code: "1100073",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FortuneHouse: {
      href: "/casino/ezugi/FortuneHouse",
      code: "1100007",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitShopMegaways: {
      href: "/casino/ezugi/FruitShopMegaways",
      code: "1100094",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenCryptex: {
      href: "/casino/ezugi/GoldenCryptex",
      code: "1100147",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenTsar: {
      href: "/casino/ezugi/GoldenTsar",
      code: "1100149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GorillaKingdom: {
      href: "/casino/ezugi/GorillaKingdom",
      code: "1100088",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MystryReels: {
      href: "/casino/ezugi/MystryReels",
      code: "1100061",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RageoftheSea: {
      href: "/casino/ezugi/RageoftheSea",
      code: "1100116",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelKingMegaways: {
      href: "/casino/ezugi/ReelKingMegaways",
      code: "1100052",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TurnFortune: {
      href: "/casino/ezugi/TurnFortune",
      code: "1100041",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TwinSpinMegaways: {
      href: "/casino/ezugi/TwinSpinMegaways",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WillysChills: {
      href: "/casino/ezugi/WillysChills",
      code: "1100128",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    VikingsSlots: {
      href: "/casino/ezugi/VikingsSlots",
      code: "1100124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
  },

  providers: {
    evolution: {
      href: "/casino/evolution",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/providers/Evolution.jpg",
      name: "Evolution",
    },
    ezugi: {
      href: "/casino/ezugi",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/providers/Ezugi.jpg",
      name: "Ezugi",
    },
    supernowa: {
      href: "/casino/supernowa",
      code: "",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      imgUrl: "./images/providers/Supernowa.jpg",
      name: "Supernowa",
    },
    worldcasino: {
      href: "/casino/worldcasino",
      code: "",
      casino: "wco",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/providers/worldcasino.jpg",
      name: "World Casino",
    },
    xpg: {
      href: "/casino/xpg",
      code: "",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      imgUrl: "./images/providers/XPG.jpg",
      name: "XPG",
    },
    qtech: {
      href: "/casino/qtech",
      code: "",
      casino: "qtech",
      provider: "QT",
      homeUrl: "",
      imgUrl: "./images/providers/Qtech.jpg",
      name: "Qtech",
    },
    vivo: {
      href: "/casino/vivo",
      code: "vivo",
      casino: "vivo",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/providers/vivo.jpg",
      name: "Vivo",
    },
    kingmaker: {
      href: "/kingmaker",
      code: "vivo",
      casino: "vivo",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/providers/Kingmaker.jpg",
      name: "Vivo",
    },
  },
  providersmob: {
    evolution: {
      href: "/casino/evolution",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/providers/mobile/Evolution1.jpg",
      name: "Evolution",
    },
    ezugi: {
      href: "/casino/ezugi",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/providers/mobile/Ezugi1.jpg",
      name: "Ezugi",
    },
    supernowa: {
      href: "/casino/supernowa",
      code: "",
      casino: "wco",
      provider: "SN",
      homeUrl: "",
      imgUrl: "./images/providers/mobile/Supernowa1.jpg",
      name: "Supernowa",
    },
    worldcasino: {
      href: "/casino/worldcasino",
      code: "",
      casino: "wco",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/providers/mobile/WorldCasino1.jpg",
      name: "World Casino",
    },
    xpg: {
      href: "/casino/xpg",
      code: "",
      casino: "wco",
      provider: "XPG",
      homeUrl: "",
      imgUrl: "./images/providers/mobile/Xpg1.jpg",
      name: "XPG",
    },
    qtech: {
      href: "/casino/qtech",
      code: "",
      casino: "qtech",
      provider: "QT",
      homeUrl: "",
      imgUrl: "./images/providers/mobile/Qtech1.jpg",
      name: "Qtech",
    },
  },
  royalgaming: {
    CricketWar: {
      href: "/casino/ezg-cricket-war",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_cricket_war.jpg",
      name: "Cricket War",
    },
    AmarAkbarAnthony: {
      href: "/casino/aura-amar-akbar-anthony",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_a3.jpg",
      name: "Amar Akbar Anthony",
    },
    Cards3Judgement: {
      href: "/casino/aura-3-cards-judgement",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_3cards_judgement.jpg",
      name: "3 Cards Judgement",
    },
    CasinoWar: {
      href: "/casino/aura-casino-war",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_casino_war.jpg",
      name: "Casino War",
    },
    WorliMatka: {
      href: "/casino/sn-worli-matka",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_worli_matka.jpg",
      name: "Worli Matka",
    },
    BollywoodCasino: {
      href: "/casino/aura-bollywood-casino",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_bollywood_casino.jpg",
      name: "Bollywood Casino",
    },
    HiLow: {
      href: "/casino/aura-hi-low",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_high_low.jpg",
      name: "Hi Low",
    },
    MuflisTeenPatti: {
      href: "/casino/sn-muflis-teenpatti",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_muflis_teenpatti.jpg",
      name: "Muflis Teen Patti",
    },
    UpDown7: {
      href: "/casino/aura-7-up-down",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_7up_7down.jpg",
      name: "7 Up Down",
    },
    RouletteAura: {
      href: "/casino/aura-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_roulette.jpg",
      name: "7 Up Down",
    },
    TeenPatti: {
      href: "/casino/sn-teen-patti",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_teen_patti.jpg",
      name: "Teen Patti",
    },
    DragonTiger: {
      href: "/casino/ezg-dragon-tiger",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_dragon_tiger.jpg",
      name: "Dragon Tiger",
    },
  },
  royalgamingmob: {
    CricketWar: {
      href: "/casino/ezg-cricket-war",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_cricket_war.jpg",
      name: "Cricket War",
    },
    AmarAkbarAnthony: {
      href: "/casino/aura-amar-akbar-anthony",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_a3.jpg",
      name: "Amar Akbar Anthony",
    },
    Cards3Judgement: {
      href: "/casino/aura-3-cards-judgement",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_3cards_judgement.jpg",
      name: "3 Cards Judgement",
    },
    CasinoWar: {
      href: "/casino/aura-casino-war",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_casino_war.jpg",
      name: "Casino War",
    },
    WorliMatka: {
      href: "/casino/sn-worli-matka",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_worli_matka.jpg",
      name: "Worli Matka",
    },
    BollywoodCasino: {
      href: "/casino/aura-bollywood-casino",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgaming/rg_bollywood_casino.jpg",
      name: "Bollywood Casino",
    },
  },
  royalgamingvirtual: {
    BaccaratVirtual: {
      href: "/casino/aura-baccarat-virtual",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_baccarat_vr.jpg",
      name: "Baccarat Virtual",
    },
    TeenPattiVirtual: {
      href: "/casino/aura-teenpatti-open-virtual",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_teen_patti_vr.jpg",
      name: "Teen Patti Virtual",
    },
    AndarBaharVirtual: {
      href: "/casino/aura-andar-bahar-virtual",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_andar_bahar_vr.jpg",
      name: "Andar Bahar Virtual",
    },
    Cards32Virtual: {
      href: "/casino/aura-32-cards-casino-virtual",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_32cards_vr.jpg",
      name: "32 Cards Virtual",
    },
    DragonTigerVirtual: {
      href: "/casino/aura-dragon-tiger-virtual",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_dtl_vr.jpg",
      name: "Dragon Tiger Virtual",
    },
    HilowVirtual: {
      href: "/casino/aura-hi-low-virtual",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_high_low_vr.jpg",
      name: "Hi Low Virtual",
    },
    TeenPattiT20Virtual: {
      href: "/casino/aura-teenpatti-t20-virtual",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_teen_patti_vr.jpg",
      name: "Teenpatti T20 (Virtual)",
    },
    AmarAkbarAnthonyVirtual: {
      href: "/casino/aura-amar-akbar-anthony-virtual",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_a3_vr.jpg",
      name: "Amar Akbar Anthony (Virtual)",
    },
    SuperOverVirtual: {
      href: "/casino/aura-sper-over",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_super_over_vr.jpg",
      name: "Super Over",
    },
    TrioVirtual: {
      href: "/casino/aura-trio-virtual",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_trio_vr.jpg",
      name: "Trio Virtual",
    },
    MuflisTeenPattiVirtual: {
      href: "/casino/aura-muflis-teenpatti-virtual",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_muflis_tp_vr.jpg",
      name: "Muflis Teen Patti Virtual",
    },
  },
  royalgamingvirtualmob: {
    BaccaratVirtual: {
      href: "/casino/aura-baccarat-virtual",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_baccarat_vr.jpg",
      name: "Baccarat Virtual",
    },
    TeenPattiVirtual: {
      href: "/casino/aura-teenpatti-open-virtual",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_teen_patti_vr.jpg",
      name: "Teen Patti Virtual",
    },
    AndarBaharVirtual: {
      href: "/casino/aura-andar-bahar-virtual",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_andar_bahar_vr.jpg",
      name: "Andar Bahar Virtual",
    },
    Cards32Virtual: {
      href: "/casino/aura-32-cards-casino-virtual",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_32cards_vr.jpg",
      name: "32 Cards Virtual",
    },
    DragonTigerVirtual: {
      href: "/casino/aura-dragon-tiger-virtual",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_dtl_vr.jpg",
      name: "Dragon Tiger Virtual",
    },
    HilowVirtual: {
      href: "/casino/aura-hi-low-virtual",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/royalgamingvirtual/rg_high_low_vr.jpg",
      name: "Hi Low Virtual",
    },
  },
  livecasino: {
    AndarBahar: {
      href: "/casino/ezg-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/livecasino/andarbahar.png",
      name: "Andar Bahar",
    },
    TeenPatti: {
      href: "/casino/ezg-teen-patti-3-card",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/livecasino/teenpatti.png",
      name: "Teen Patti",
    },
    BlackjackD: {
      href: "/casino/ezgevo-speed-blackjack-d",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/livecasino/blackjackd.jpg",
      name: "Blackjack D",
    },
    NoCommissionBaccarat: {
      href: "/casino/ezg-no-commission-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/livecasino/no_commission_baccarat.png",
      name: "No Comm Baccarat ",
    },
    UltimateRoulette: {
      href: "/casino/ezg-ultimate-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/livecasino/ezg_ultimate_roulette1.jpg",
      name: "Ultimate Roulette",
    },
    Lucky7: {
      href: "/casino/	ezg-lucky-7",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/livecasino/lucky7.png",
      name: "Lucky 7",
    },
    VIPRoulette: {
      href: "/casino/ezgevo-vip-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/livecasino/evo_liveviproulette.jpg",
      name: "VIP Roulette",
    },
    DreamCatcher: {
      href: "/casino/ezgevo-dream-catcher",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/livecasino/evo_livedreamcatcher.jpg",
      name: "Dream Catcher",
    },
  },
  livecasinomob: {
    AndarBahar: {
      href: "/casino/ezg-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/livecasino/andarbahar.png",
      name: "Andar Bahar",
    },
    TeenPatti: {
      href: "/casino/ezg-teen-patti-3-card",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/livecasino/teenpatti.png",
      name: "Teen Patti",
    },
    BlackjackD: {
      href: "/casino/ezgevo-speed-blackjack-d",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/livecasino/blackjackd.jpg",
      name: "Blackjack D",
    },
    NoCommissionBaccarat: {
      href: "/casino/ezg-no-commission-baccarat",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/livecasino/no_commission_baccarat.png",
      name: "No Comm Baccarat ",
    },
    UltimateRoulette: {
      href: "/casino/ezg-ultimate-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/livecasino/ezg_ultimate_roulette1.jpg",
      name: "Ultimate Roulette",
    },
    Lucky7: {
      href: "/casino/	ezg-lucky-7",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/livecasino/lucky7.png",
      name: "Lucky 7",
    },
  },
  sportsbook: {
    Cricket: {
      href: "/betby",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/Cricket.svg",
      name: "Cricket",
    },
    Football: {
      href: "/betby",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/Football.svg",
      name: "Football",
    },
    Tennis: {
      href: "/betby",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/Tennis.svg",
      name: "Tennis",
    },
    BadMinton: {
      href: "/betby",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/badminton-icon.svg",
      name: "BadMinton",
    },
    Basketball: {
      href: "/betby",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/basketball-icon.svg",
      name: "Basketball",
    },
    Boxing: {
      href: "/betby",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/boxing-icon.svg",
      name: "Boxing",
    },
    Chess: {
      href: "/betby",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/chess-icon.svg",
      name: "Chess",
    },
    Cycling: {
      href: "/betby",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/cycling.svg",
      name: "Cycling",
    },
    Darts: {
      href: "/betby",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/Darts.svg",
      name: "Darts",
    },
    EGames: {
      href: "/betby",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/egames-icon.svg",
      name: "E Games",
    },
    HorseRacing: {
      href: "/betby",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/horseracing-icon.svg",
      name: "Horse Racing",
    },
    Rugby: {
      href: "/betby",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/rugby-icon.svg",
      name: "Rugby",
    },
    Snooker: {
      href: "/betby",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/Snooker.svg",
      name: "Snooker",
    },
    Tabletennis: {
      href: "/betby",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/tabletennis-icon.svg",
      name: "Table Tennis",
    },
  },
  sportsbookmob: {
    Cricket: {
      href: "/betby?bt-path=%2F%3FtopSport%3Dcricket-21",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/Cricket.svg",
      name: "Cricket",
    },
    Football: {
      href: "/betby?bt-path=%2F%3FtopSport%3Dsoccer-1",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/Football.svg",
      name: "Football",
    },
    Tennis: {
      href: "/betby?bt-path=%2Ftennis-5",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/Tennis.svg",
      name: "Tennis",
    },
    Rugby: {
      href: "/betby?bt-path=%2Frugby-12",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/rugby-icon.svg",
      name: "Rugby",
    },
    Tabletennis: {
      href: "betby?bt-path=%2Ftable-tennis-20",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      imgUrl: "./images/sportsbook/tabletennis-icon.svg",
      name: "Table Tennis",
    },
  },
};
