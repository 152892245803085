import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Slider from "react-slick";

const MainSlider = ({ banners }) => {
  const settings = {
    dots: false,
    fade: true,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      <Slider {...settings}>
        {banners &&
          banners.length &&
          banners.map((b, index) => {
            return (
              <div className="position-relative" key={index}>
                <img src={b.link} alt="First Slide" />
                <div className="banner-btn">
                  {(b?.ctabtns.length &&
                    b.ctabtns.map((c, i) => {
                      return (
                        <Button
                          variant="primary"
                          className="start-btn button-primary"
                          onClick={() => {
                            window.location.href = c.actionLink;
                          }}
                          key={i}
                        >
                          {c.label}
                        </Button>
                      );
                    })) ||
                    null}
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default MainSlider;
